<script setup>
import Button from '@/Components/Button/Button.vue';
import ButtonLink from '@/Components/Button/Link.vue';
import Footer from '@/Components/Footer/FooterLogin.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

let route = inject('route');

defineProps({
    status: String,
});

const form = useForm({
    email: '',
});

const submit = () => {
    form.post(route('password.email'), {
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <GuestLayout title="Log in">
        <div class="container flex flex-col items-center w-full relative -top-[200px]">
            <div class="w-full p-8 bg-white rounded-2xl max-w-[550px] text-blue">
                <h1 class="text-[32px] font-bold mb-2">{{ $t('Forgot your password?') }}</h1>

                <div v-if="status" class="mb-4 text-base font-medium text-pink">
                    {{ status }}
                </div>

                <div v-if="!status">
                    <p class="mb-10 text-base">
                        {{ $t('Enter your e-mail and we send you a reset link') }}
                    </p>

                    <form @submit.prevent="submit">
                        <FormKit
                            type="email"
                            name="email"
                            :label="$t('Email')"
                            v-model="form.email"
                            :errors="form.errors.email"
                            :value="form.email"
                            validation="required|email"
                        />

                        <Button full-width :processing="form.processing">{{ $t('Send e-mail') }}</Button>
                    </form>
                </div>
                <div v-else>
                    <ButtonLink pink :href="route('login')" class="mt-8">{{ $t('Log in') }}</ButtonLink>
                </div>
            </div>
            <Footer />
        </div>
    </GuestLayout>
</template>
